import React from 'react';
import styled from 'styled-components';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
    line-height: 1.5;
  }

  input,
  textarea {
    flex-basis: 50%;
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 300;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: rgba(30, 48, 59, 1);
    border: 0;

    &:focus {
      outline: #eaca27 solid 1px;
    }
  }
`;

const FormButton = styled.button`
  background-color: #eaca27;
  border: 1px solid #eaca27;
  border-radius: 100px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.75rem;
  color: #000;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #c3a300;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const ContactForm = () => {
  return (
    <FormContainer name="Contact form" method="POST" netlify action="/contact">
      <input type="hidden" name="form-name" value="Contact Form" />
      <InputContainer>
        <label htmlFor="email">Email</label>
        <input type="email" name="email" />
      </InputContainer>
      <InputContainer>
        <label htmlFor="message">Message</label>
        <textarea name="message" rows="5" />
      </InputContainer>
      <FormButton type="submit">Send</FormButton>
    </FormContainer>
  );
};

export default ContactForm;
