import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Section from '../components/section';
import { Horizontal, Column } from '../components/section';
import { OutlineButton } from '../components/button';
import Title from '../components/title';
import ContactForm from '../components/contactForm';

const Contact = ({ data }) => (
  <Layout>
    <Section>
      <Title pageTitle={data.contactJson.title} />
      <Horizontal>
        <Column>
          <div
            dangerouslySetInnerHTML={{
              __html: data.contactJson.content.childMarkdownRemark.html,
            }}
          />
          <OutlineButton as="a" to="mailto:audio@jannemikkola.com">
            Send email
          </OutlineButton>
          <OutlineButton
            as="a"
            to="https://www.facebook.com/Janne-Mikkola-Audio-Addict-111468580199501/"
          >
            Facebook
          </OutlineButton>
        </Column>
        <Column>
          <ContactForm />
        </Column>
      </Horizontal>
    </Section>
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
