import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const StyledButton = styled(Link)`
  display: block;
  border: 1px solid #eaca27;
  border-radius: 100px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.75rem;
  color: #000;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  user-select: none;
  cursor: pointer;

  ${MEDIA.MIN_TABLET`
    display: inline-block;
    margin-bottom: 0;
    margin-right: 1rem;
  `};
`;

export const OutlineButton = styled(StyledButton)`
  background: transparent;
  color: #fff;

  &:hover {
    background: #eaca27;
    color: #000;
  }
`;
export const FilledButton = styled(StyledButton)`
  background: rgba(234, 202, 39, 100);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.5);

  &:hover {
    background: rgba(234, 202, 39, 0);
    color: #fff;
  }
`;

const Button = ({ children, outline }) => {
  return (
    <>
      {outline ? (
        <OutlineButton>{children}</OutlineButton>
      ) : (
        <FilledButton>{children}</FilledButton>
      )}
    </>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  outline: PropTypes.bool,
};

export default Button;
